import { TagColors } from '@venncity/venn-ds';
import type { ValueOf } from 'type-fest';
import type { LinkType } from '~/codegen/graphql';

export const Status = {
  ACTIVE: 'Active',
  SCHEDULED: 'Scheduled',
  DRAFT: 'Draft',
  INACTIVE: 'Inactive',
} as const;

export const TagColorMap: Record<string, TagColors> = {
  [Status.ACTIVE]: TagColors.SUCCESS,
  [Status.SCHEDULED]: TagColors.DEFAULT,
  [Status.DRAFT]: TagColors.WARNING,
  [Status.INACTIVE]: TagColors.ERROR,
};

export interface FeaturedSlideFormDTO {
  id: string;
  name?: string;
  title?: string;
  linkType?: LinkType | null;
  callToActionEntityId?: string;
  callToActionLabel?: string;
  callToActionType?: string;
  publishStartDate: string;
  publishEndDate: string;
  image?: { url?: string; id?: string; format?: string };
  buildings?: [{ name: string; id: string }];
  communityId: string;
  timezone: string;
}

export interface FeaturedSlideDetailsDTO {
  id: string;
  name?: string;
  title?: string;
  linkType?: LinkType | 'all' | null;
  callToActionEntityId?: string;
  callToActionLabel?: string;
  callToActionType?: string;
  status?: ValueOf<typeof Status>;
  createBy: { fullName: string; profilePictureUrl: string };
  publishStartDate: string;
  publishEndDate: string;
  image?: { url?: string; id?: string; format?: string };
  buildings?: [{ name: string; id: string }];
  priority: number;
  audience: 'Specific Buildings' | 'All buildings';
}

export const FeaturedSlideFormTabs = {
  Form: 'form',
  Preview: 'preview',
} as const;

export const FeaturedSlideFormAction = {
  Save: 'save',
  Preview: 'preview',
} as const;

export const linkTypeChoices = {
  event: 'Event',
  place: 'Place',
  service: 'Service',
  user: 'User',
  generalInfoPage: 'General info page',
  chatGroup: 'Interest group',
  selfProfile: 'Self profile',
  createDiscussion: 'Create discussion',
  custom: 'Custom',
} as const;
